<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex my-3 align-center">
        <v-icon class="mr-2" large color="primary">{{ `mdi-numeric-${$attrs.number}-box` }}</v-icon>
        <p>
          <slot></slot>
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>

export default {
  inheritAttrs: false,

  components: {
  },

  props: {
  },

  data() {
    return {
    };
  },

  computed: {
  },

  created() {
  }
};
</script>

<style scoped>
</style>
