<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">メニューの設定</h1>
        <banner-hint>
          HPヘッダー下部のメニューバーとスマホ版のサイドメニュー内に表示するメニューを編集できます。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        class="mt-3 mb-5"
      >
        <v-card
          class="py-5 px-3"
          elevation="1"
        >
          <v-row>
            <v-col
              class="mx-6"
            >
              <num-instruction number="1">メニュー一覧からホームページに表示したいメニューを選びます。</num-instruction>
              <!-- メニューマスター -->
              <v-select
                class="mx-3"
                v-model="selectedIds"
                chips
                label="メニュー一覧"
                multiple
                :items="masterMenus"
                item-value="id"
                item-text="menu_name"
                item-color="primary"
              ></v-select>
            </v-col>
          </v-row>

          <num-instruction number="2" class="ml-3">必要に応じてメニュー名の編集、メニューの並べ替えをします。</num-instruction>
          <icon-info icon="lightbulb-on-outline" class="mb-5 ml-13">
            並べ替えは<v-icon small>mdi-gesture-double-tap</v-icon>をドラッグ＆ドロップで
          </icon-info>

          <v-form ref="form">
            <v-row no-gutters>
              <draggable
                class="d-flex flex-wrap"
                :class="selectedIds.length <= 1 && 'flex-grow-1'"
                v-model="selectedIds"
                handle=".draggable"
                animation="100"
                delay="0"
              >
                <v-col cols="12" md="6" lg="4"
                  class="mb-5 pt-3 px-7"
                  v-for="(menu, index) in selectedMenuObjsFromIds"
                  :key="menu.id"
                >

                  <!-- 入力一列 -->
                    <v-row no-gutters>
                      <!-- アイコン＆バッジ -->
                      <v-col cols="2"
                        class="draggable align-self-center"
                      >
                        <v-badge
                          class=""
                          :content="index + 1"
                          overlap
                          color="accent"
                        >
                          <v-avatar
                            color="primary"
                            size="40"
                          >
                            <v-icon>
                              mdi-gesture-double-tap
                            </v-icon>
                          </v-avatar>
                        </v-badge>
                      </v-col>
                      <!-- テキストフィールド -->
                      <v-col cols="8"
                        class="px-1"
                      >
                        <v-text-field
                          v-model.trim="menu.display_name"
                          required
                          placeholder="HP上の表示名"
                          counter="8"
                          :rules="[valiRules.required, valiRules.max8]"
                        ></v-text-field>
                      </v-col>
                      <!-- ボタン -->
                      <v-col cols="2"
                        class="d-flex justify-start align-center"
                        :class="$vuetify.breakpoint.mdAndUp ? 'justify-start' : 'justify-end'"
                      >
                        <v-btn
                          class="ml-2"
                          text
                          depressed small
                          color="primary"
                          @click="deleteAMenu(index)"
                        >削除</v-btn>
                      </v-col>
                    </v-row>
                </v-col>
              </draggable>
            </v-row>
          </v-form>

          <!-- 登録ボタン -->
          <v-row no-gutters>
            <v-col class="pt-5 pl-5">
              <v-btn
                depressed
                color="primary"
                @click="updateMenus()"
              >メニューを登録</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import draggable from "vuedraggable";
import $literals from '@/literals.js'
import { API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import NumberInstruction from "@/components/_NumberInstruction.vue";
import IconInfo from "@/components/_IconInfo.vue";
import BannerHint from "@/components/_BannerHint.vue";

export default {
  components: {
    draggable,
    'loader': Loader,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
    'num-instruction': NumberInstruction,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      masterMenus: [],
      siteMenus: [],
      selectedIds: [],
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      publicApi: new ApiTool(API_ENDPOINT + '/', this.shopData),
    };
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    selectedMenuObjsFromIds() {
      const menus = []
      //選択されたID配列からメニューのオブジェクト配列を作成
      this.selectedIds.map( id => {
        this.masterMenus.map( menu => {
          if (id === menu.id) {
            menus.push(menu)
          }
        })
      })
      return menus
    }
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await this.getMenuMaster()

      await Promise.all([
        this.getSiteMenus(),
      ])

      this.loading = false
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // メニューマスター取得
    //**************************************************
    getMenuMaster() {
      return this.publicApi.getReqSystemPublic('site-menu-master/').then( results => {
        if (!results || !results.length) return

        this.masterMenus = results.map( row => {
          row.display_name = row.menu_name
          return row
        })
      })
    },

    //**************************************************
    // API req: GET
    //**************************************************
    getSiteMenus() {
      return this.adminApi.getReqWithAuth('site-menu/').then( siteMenus => {
        if (!siteMenus) return

        //マスター配列に表示名を挿入＆セレクションIDを作成
        siteMenus.map( menu => {
          this.masterMenus.map(master => {
            if (menu.menu_id === master.id) {
              master.display_name = menu.display_name
              this.selectedIds.push(master.id)
            }
          })
        })

      })
    },

    //**************************************************
    //メニュー一覧更新
    //**************************************************
    updateMenus() {
      if (!this.selectedMenuObjsFromIds.length || !this.$refs.form.validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const payload = JSON.stringify(this.selectedMenuObjsFromIds)

      this.adminApi.apiReqWithData('PUT', 'update/site-menus/', payload).then(() => {
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //メニュー一つ削除
    //**************************************************
    deleteAMenu(index) {
      const apiPath = 'delete/site-menu/' + this.selectedMenuObjsFromIds[index].id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.selectedIds.splice(index, 1)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },
  }
}
</script>

<style scoped>
</style>
